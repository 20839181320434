/* eslint-disable */
import React, { Component } from 'react';
import { store } from 'react-notifications-component';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import cookie from 'js-cookie'
import axios from 'axios';
import 'react-notifications-component/dist/theme.css';
// import http from '../../utility/httpCommon';
import moment from 'moment';
import IsLoading from '../../assets/IsLoading';
import httpServices from '../../utility/httpServices';

import ErrorModal from '../../assets/ErrorModal';
const cookies = new Cookies

var http = axios.create({
    baseURL:
        ` ${localStorage.getItem('APIBaseURL')}/facility/api/facilityrequest/`
    ,
    headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'X-API-Key': "e8q05322dhg429b4f778a4pa7yt562eb37q",
    }

})


var ruler;

class Connect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            result: '',
            loading: false,
            error: false,
            errorMessage: '',
            access: ''
        };
    }

    checkurl = () => {
        console.log(this.props.location.search)
        const parsed = queryString.parse(this.props.location.search);
        cookies.set("fromDashboard", parsed.fromdashboard)
        const fromDashboard = parsed.fromdashboard
        localStorage.setItem("fromDashboard", parsed.fromDashboard)
        localStorage.setItem("requestId", parsed.requestId)
        cookies.set("requestId", parsed.requestid)
        const requestId = parsed.requestid
        const { history } = this.props;

        //this change with respect to each module
        //Edogov_(enter module name);
        const ModuleName = 'Edogov_Facility';

        //check if there is a jwt token saved before
        const AccessToken = cookies.get(ModuleName + '_AccessToken');

        //check if there is an app token saved before
        const AppToken = cookies.get('token');

        //get the base url from query string(edogoverp.com)
        const baseurl = parsed.base;

        // Get the help Link from the query String
        const helpLink = parsed.help;
        cookies.set('helpLink', helpLink);
        if (parsed.emp === '') {
            this.setState({ loading: false })
            this.setState({ error: true })
            this.setState({ errorMessage: 'Expired Token, please login again' })

        }

        else if (parsed.emp !== '') {
            this.setState({
                loading: true,
            });



            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)
                    httpServices.post('', {

                        lattitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString()
                    })
                        .then(res => {
                            console.log(res)
                            cookies.set('location', res.data?.location ?? "", { path: "/" })
                        })
                });
            } else {
                alert("Sorry, your browser does not support HTML5 geolocation.");
            }


            const authurl = '/facility/api/authenticate'; //change this to the module authenthication endpoint

            axios
                .get(baseurl + authurl + '/' + parsed.emp, {

                    headers: {
                        'Access-Control-Allow-Credentials': true,
                        crossorigin: true,
                        'Access-Control-Allow-Methods': 'GET',
                        'Access-Control-Allow-Origin': '*',
                        'X-API-Key': "e8q05322dhg429b4f778a4pa7yt562eb37q",
                    },
                })

                .then((user_auth) => {

                    axios.get(`${parsed.base}/facility/api/facilityrequest/get-access-priviledge`, {
                        params: {
                            modulename: null
                        },
                        headers: {
                            "content-type": "application/json",
                            'Authorization': `Bearer ${user_auth.data.token}`,
                            'X-API-Key': "e8q05322dhg429b4f778a4pa7yt562eb37q",
                        }

                    })
                        .then((res) => {
                            console.log(res)
                            this.setState({ access: res?.data?.data[0]?.userRoleName.toLowerCase() })
                            ruler = res?.data?.data[0]?.userRoleName.toLowerCase();
                            console.log(ruler)

                            console.log('logging api response', user_auth.data);
                            console.log('token to be stored ====', user_auth.data.token);
                            cookies.set('userId', user_auth.data.data.userId);
                            cookies.set('role', user_auth.data.data.roles);
                            cookies.set('email', user_auth.data.data.email);
                            cookies.set('picture', user_auth.data.data.profilePicture);
                            cookies.set('userSequence', user_auth.data.data.userSequence ?? "", { path: "/" })

                            //cookies.set(ModuleName + "_FirstName", user_auth.data.firstName);
                            cookies.set(
                                'fullname',
                                `${user_auth.data.data.firstName} ${user_auth.data.data.lastName}`,
                            );
                            cookies.set('loginTime', moment().format('HH:mm a'));
                            // cookies.set(ModuleName + "_LastName", user_auth.data.lastName);
                            cookies.set('token', user_auth.data.token);
                            cookie.set('token', user_auth.data.token);
                            localStorage.setItem('token', user_auth.data.token);
                            cookies.set('tokenExist', true);
                            cookies.set('firstName', user_auth.data.data.firstName);
                            cookies.set('lastName', user_auth.data.data.lastName);
                            cookies.set('staffNumber', user_auth.data.data.staffNumber);

                            cookies.set('mda', user_auth.data.data.mda)

                            cookies.set('homeLink', user_auth.data.data.homeUrl); //for home redirection
                            cookies.set(ModuleName + '_AccessToken', parsed.emp);
                            cookies.set('APIBaseURL', parsed.base);
                            cookie.set('APIBaseURL', parsed.base);
                            localStorage.setItem('APIBaseURL', parsed.base);


                            if (AppToken !== null && AppToken !== '') {
                                this.setState({ loading: true });
                                const { access } = this.state;
                                const { history } = this.props;
                                // console.log('')
                                //redirect to dashboard
                                console.log(access);
                                if (fromDashboard === 'true' && ruler !== "technical") {
                                    history.push('/facility-portal/review-request')
                                }
                                else if (fromDashboard === 'true' && ruler === "technical") {
                                    history.push('/facility-portal/manage-request')
                                }
                                else {
                                    history.push('/facility-portal');
                                }
        
        
                            } else {
                                store.addNotification({
                                    title: 'Error!',
                                    message: user_auth.message + 'redirecting...',
                                    type: 'danger',
                                    insert: 'bottom',
                                    container: 'bottom-left',
                                    animationIn: ['animate__animated', 'animate__fadeIn'],
                                    animationOut: ['animate__animated', 'animate__fadeOut'],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: true,
                                    },
                                });
                                window.location.assign(homeUrl)
                            }
                        })
                        .catch((err) => {
                            console.log(err)

                            console.log('logging api response', user_auth.data);
                            console.log('token to be stored ====', user_auth.data.token);
                            cookies.set('userId', user_auth.data.data.userId);
                            cookies.set('role', user_auth.data.data.roles);
                            cookies.set('email', user_auth.data.data.email);
                            cookies.set('picture', user_auth.data.data.profilePicture);
                            cookies.set('userSequence', user_auth.data.data.userSequence ?? "", { path: "/" })

                            //cookies.set(ModuleName + "_FirstName", user_auth.data.firstName);
                            cookies.set(
                                'fullname',
                                `${user_auth.data.data.firstName} ${user_auth.data.data.lastName}`,
                            );
                            cookies.set('loginTime', moment().format('HH:mm a'));
                            // cookies.set(ModuleName + "_LastName", user_auth.data.lastName);
                            cookies.set('token', user_auth.data.token);
                            cookie.set('token', user_auth.data.token);
                            localStorage.setItem('token', user_auth.data.token);
                            cookies.set('tokenExist', true);
                            cookies.set('firstName', user_auth.data.data.firstName);
                            cookies.set('lastName', user_auth.data.data.lastName);
                            cookies.set('staffNumber', user_auth.data.data.staffNumber);

                            cookies.set('mda', user_auth.data.data.mda)

                            cookies.set('homeLink', user_auth.data.data.homeUrl); //for home redirection
                            cookies.set(ModuleName + '_AccessToken', parsed.emp);
                            cookies.set('APIBaseURL', parsed.base);
                            cookie.set('APIBaseURL', parsed.base);
                            localStorage.setItem('APIBaseURL', parsed.base);

                            if (fromDashboard === 'true' && ruler !== "technical") {
                                history.push('/facility-portal/review-request')
                            }
                            else if (fromDashboard === 'true' && ruler === "technical") {
                                history.push('/facility-portal/manage-request')
                            }
                            else {
                                history.push('/facility-portal');
                            }
                        })





                    
                })

                // .catch((error) => {
                //     // console.log(error);
                //     store.addNotification({
                //         title: 'Error!',
                //         message: 'Authentication Failed',
                //         type: 'danger',
                //         insert: 'bottom',
                //         container: 'bottom-left',
                //         animationIn: ['animate__animated', 'animate__fadeIn'],
                //         animationOut: ['animate__animated', 'animate__fadeOut'],
                //         dismiss: {
                //             duration: 5000,
                //             onScreen: true,
                //         },
                //     });
                // });
        }

        // console.log('logging baseurl and Emp', parsed.url, parsed.emp);
    };

    componentDidMount() {

        this.checkurl();
    }



    render() {
        const { loading } = this.state;
        return (
            <div className='w-100 flex flex-v-center flex-h-center' style={{ minHeight: '105vh' }}>
                {loading ? <IsLoading /> : ''}
                <ErrorModal show={this.state.error} closeModal={this.closeErrorModal} action={this.state.errorMessage} />

            </div>
        );
    }
}

export default Connect;
