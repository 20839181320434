import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import IsLoading from './assets/IsLoading'
import ReactNotifications from 'react-notifications-component';
import ProtectedRoute from './routes/ProtectedRoute';
import { lazy, Suspense } from 'react';
import "antd/dist/antd.css";
import Cookies from 'universal-cookie'
import SwapTableContextProvider from './components/Contexts/SwapTableContext'

import './App.css';
import Connect from './components/home/connect';





const Home = lazy(() => import('./components/home/index'))
const UserPortal = lazy(() => import('./components/UserPortal'))



const cookies = new Cookies()

function App() {
// let [loggedIN, setLoggedIN] = useState(false)



//   if(cookies.get('token')){
//   setLoggedIN(true)
// }

const history = createBrowserHistory();
return (
  <SwapTableContextProvider>
  
  <Router history={history}>
    <div>
      <ReactNotifications />
      
      <Suspense fallback={<IsLoading/>}>
      <Switch>
      
        <Route path="/" exact component={Connect} />

        <ProtectedRoute path="/facility-portal/" component={UserPortal}  />
        {/* <Route render={() => <h1>Error 404. Page not found.</h1>} /> */}
      
      </Switch>
      </Suspense>
    </div>
  </Router>
  </SwapTableContextProvider>

);

}


export default App;
